@font-face {
    font-family: "Montserrat-Regular";
    src: local("Montserrat-Regular"),
     url("../fonts/Montserrat-Regular.ttf") format("truetype");
} 

 @font-face {
        font-family: "MinionProRegular";
        src: local("Minion Pro Regular.ttf"),
         url("../fonts/MinionPro-Regular.otf") format("truetype");
        }

@font-face {
            font-family: "MinionProBold";
            src: local("Minion Pro Regular.ttf"),
             url("../fonts/MinionPro-Bold.otf") format("truetype");
            }
@font-face {
            font-family: "Bodonifl";
            src: local("Bodonifl.ttf"),
            url("../fonts/BodoniflfBolditalic.ttf") format("truetype");
            }

    .font-montserrat{
        font-family: 'Montserrat-Regular';
    }
    
    .font-minionproregular{
        font-family: 'MinionProRegular';
    }
    .font-minionprobold{
        font-family: 'MinionProBold';
    }
    .font-bodonifl{
        font-family: 'Bodonifl';
    }