.konzortia-blue{
    background-color: #061228;
    
}

.konzortia-blue-modal{
    background-color: 
    rgba(0, 0, 0, 0.5);
}

.konzortia-light-blue{
    background-color: #015A91;
}
.klb-text{
    color: #015A91;
}

.kon-lighterblue-bg{
    background-color: #2698c6;
}
.bg-input{
    background-color: #384153;
}
.bg-about{
    background: #EDEDED;

}
.Konzortia-CTA-BTN{
    background-color:#015a91;
}

.konzortia-blue-text{
    color: #061228;
}

.konzortia-borderline{
    border-left: #4AABCE 2px solid;
}

.konzortia-gradient{
    background: rgb(6,18,40);
    background: linear-gradient(270deg, rgba(6,18,40,1) 0%, rgba(44,128,201,1) 50%, rgba(6,18,40,1) 100%); 
}
